require('es6-object-assign/auto')
var angular = require('angular')
var wgService = require('./services/wg.service').wgService
var translateFilter = require('./filters/translate.filter').translateFilter
var helperService = require('./services/helper.service').helperService
var logglyHttpWrapperService = require('./services/loggly-http-wrapper.service').logglyHttpWrapperService
var httpErrorInterceptor = require('./services/http-error-interceptor.service').httpErrorInterceptor
var appLoader = require('./services/app-loader.provider').appLoader
var userTracking = require('./services/user-tracking.provider').userTracking
var i18n = require('./services/i18n.provider').i18n
var logglyLogger = require('csas-angular-loggly-logger')

function bootstrap (options) {
  if (options.element) {
    options.element.innerHTML = options.element.innerHTML + options.html
    options.element.setAttribute('ng-controller', options.mainCtrlName + ' as vm')
  }

  angular.module(options.appName).requires.push(logglyLogger)

  angular.module(options.appName)
    .factory('helper', helperService)
    .provider('appLoader', appLoader)
    .provider('i18n', i18n)
    .config(['$httpProvider', '$sceDelegateProvider', 'appLoaderProvider', 'userTrackingProvider', 'i18nProvider', 'LogglyLoggerProvider', configProvider])
    .filter('translate', translateFilter)
    .constant('basePath', options.basePath)
    .constant('apiBase', options.config.url)
    .constant('apiKey', options.config.apiKey)
    .constant('imagesDomain', options.config.imagesDomain)
    .constant('appConfig', options.config)
    .factory('wgService', wgService)
    .provider('userTracking', userTracking)
    .factory('logglyHttpWrapperService', logglyHttpWrapperService)
    .factory('httpErrorInterceptor', httpErrorInterceptor)
    .run(['wgService', 'appLoader', function (wgService, appLoader) {
      wgService.init(options.appName, options.inletScope, options.element)
      appLoader.setBackgroundColor((wgService.getParams() && wgService.getParams().appLoaderBackgroundColor) || '#dff2fd')
    }])

  angular.bootstrap(options.element, [options.appName])

  function configProvider ($httpProvider, $sceDelegateProvider, appLoaderProvider, userTrackingProvider, i18nProvider, LogglyLoggerProvider) {
    if (!options.supportedLanguages) {
      options.supportedLanguages = {
        cs: {},
        en: {}
      }
    }
    i18nProvider.init({ supportedLanguages: options.supportedLanguages })

    $httpProvider.defaults.headers.common['WEB-API-key'] = options.config.apiKey
    $httpProvider.defaults.headers.common['Accept-Language'] = i18nProvider.langCode
    $httpProvider.defaults.withCredentials = false
    $httpProvider.interceptors.push('httpErrorInterceptor')

    $sceDelegateProvider.resourceUrlWhitelist(['**'])

    appLoaderProvider.init(options.element)
    userTrackingProvider.init(options.appName)

    LogglyLoggerProvider.inputToken(options.config.loggly.token).deleteHeaders(true)
    LogglyLoggerProvider.inputTag('inet_web,' + options.appName)
  }
}

module.exports = {
  bootstrap: bootstrap
}
