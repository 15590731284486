/* global dataLayer */
userTracking.$inject = []

function userTracking () {
  var _inletName = null

  this.init = function (inletName) {
    _inletName = inletName
  }

  this.push = function (obj) {
    if (typeof (dataLayer) === 'undefined') {
      console.log('Missing dataLayer to push: ' + JSON.stringify(obj))
    } else {
      setTimeout(function () {
        dataLayer.push(obj)
      }, 0)
    }
  }

  this.onSlideEnd = function (elementId, description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'slideEnd',
      elementId: elementId,
      description: description
    })
  }

  this.onClick = function (elementId, description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'click',
      elementId: elementId,
      description: description
    })
  }

  this.onEnterPressed = function (elementId, description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'enterPressed',
      elementId: elementId,
      description: description
    })
  }

  this.onInputChange = function (elementId, description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'inputChange',
      elementId: elementId,
      description: description
    })
  }

  this.onRadioChange = function (elementId, description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'radioChange',
      elementId: elementId,
      description: description
    })
  }

  this.onRequest = function (requestObject, description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'request',
      requestObject: requestObject,
      description: description
    })
  }

  this.onResponse = function (responseObject, description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'response',
      responseObject: responseObject,
      description: description
    })
  }

  this.onModalOpen = function (description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'modalOpen',
      description: description
    })
  }

  this.onModalClose = function (description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'modalClose',
      description: description
    })
  }

  this.onValidityFailure = function (description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'validityFailure',
      description: description
    })
  }

  this.onFileDownload = function (description) {
    this.push({
      event: 'widgetInteraction',
      widgetId: _inletName,
      eventType: 'fileDownload',
      description: description
    })
  }

  this.outgoingStep = function (eventObj) {
    var eventObjCopy = JSON.parse(JSON.stringify(eventObj))
    eventObjCopy.matrixID = 3
    eventObjCopy.eventAction = 'Outgoing step'
    return this.pushEvent(eventObjCopy)
  }

  this.internalStep = function (eventObj) {
    var eventObjCopy = JSON.parse(JSON.stringify(eventObj))
    eventObjCopy.matrixID = 4
    eventObjCopy.eventAction = 'Internal step'
    return this.pushEvent(eventObjCopy)
  }

  this.contactSubmitFail = function (eventObj) {
    var eventObjCopy = JSON.parse(JSON.stringify(eventObj))
    eventObjCopy.matrixID = 6
    eventObjCopy.eventAction = 'Contact submit - Fail'
    return this.pushEvent(eventObjCopy)
  }

  this.interaction = function (eventObj) {
    var eventObjCopy = JSON.parse(JSON.stringify(eventObj))
    eventObjCopy.matrixID = 7
    eventObjCopy.calculatorInteractionCount = 1
    eventObjCopy.eventAction = 'Calculator interaction'
    return this.pushEvent(eventObjCopy)
  }

  this.search = function (eventObj) {
    var eventObjCopy = JSON.parse(JSON.stringify(eventObj))
    eventObjCopy.matrixID = 9
    eventObjCopy.eventAction = 'Search'
    return this.pushEvent(eventObjCopy)
  }

  this.fileDownload = function (eventObj) {
    var eventObjCopy = JSON.parse(JSON.stringify(eventObj))
    eventObjCopy.matrixID = 10
    eventObjCopy.eventAction = 'File download'
    return this.pushEvent(eventObjCopy)
  }

  this.contactSubmitSuccess = function (eventObj) {
    var eventObjCopy = JSON.parse(JSON.stringify(eventObj))
    eventObjCopy.matrixID = 12
    eventObjCopy.eventAction = 'Contact submit'
    return this.pushEvent(eventObjCopy)
  }

  this.applicationFailure = function (eventObj) {
    var eventObjCopy = JSON.parse(JSON.stringify(eventObj))
    eventObjCopy.matrixID = 13
    eventObjCopy.eventAction = 'Application failure'
    return this.pushEvent(eventObjCopy)
  }

  this.sendObject = function (eventObj) {}

  this.pushEvent = function (eventObj) {}

  this.$get = function () {
    return this
  }
}

exports.userTracking = userTracking
