logglyHttpWrapperService.$inject = ['$log', 'appConfig']

function logglyHttpWrapperService ($log, appConfig) {
  function transoformHttpResponseToLogglyJson (httpResponse) {
    return {
      environment: appConfig.loggly.environment,
      message: '',
      device: navigator.userAgent,
      req: {
        url: httpResponse.config.url,
        method: httpResponse.config.method,
        body: httpResponse.config.data
      },
      resp: {
        status: httpResponse.status,
        body: httpResponse.data
      }
    }
  }

  function log (httpResponse) {
    $log.log(transoformHttpResponseToLogglyJson(httpResponse))
  }

  function debug (httpResponse) {
    $log.debug(transoformHttpResponseToLogglyJson(httpResponse))
  }

  function info (httpResponse) {
    $log.info(transoformHttpResponseToLogglyJson(httpResponse))
  }

  function warn (httpResponse) {
    $log.warn(transoformHttpResponseToLogglyJson(httpResponse))
  }

  function error (httpResponse) {
    $log.error(transoformHttpResponseToLogglyJson(httpResponse))
  }

  return {
    log: log,
    debug: debug,
    info: info,
    warn: warn,
    error: error
  }
}

exports.logglyHttpWrapperService = logglyHttpWrapperService
