var Polyglot = require('node-polyglot')

i18n.$inject = ['$provide']

function i18n ($provide) {
  var _polyglot = new Polyglot()
  var _supportedLanguages = {}
  this.langCode = null

  this.init = function (params) {
    _supportedLanguages = params.supportedLanguages
    this.setLang(params.languageCode || getLangFromHtmlTag())
  }

  this.appendLangFile = function (languageCode, languageFileContent) {
    var content = _supportedLanguages[languageCode] || {}
    Object.assign(content, languageFileContent)
    _supportedLanguages[languageCode] = content
    if (this.langCode) {
      _polyglot.extend(_supportedLanguages[this.langCode])
    }
  }

  this.setLang = function (languageCode) {
    this.langCode = languageCode
    _polyglot.extend(_supportedLanguages[this.langCode])
    _polyglot.locale(this.langCode)
    if (this.langCode === 'cs') {
      setCsLocale()
    } else {
      setEnLocale()
    }
  }

  var getLangFromHtmlTag = function () {
    return document.querySelector('html[lang="en"]') ? 'en' : 'cs'
  }

  this.translate = function (input, options) {
    if (typeof options === 'object') {
      return _polyglot.t(input, options)
    } else {
      return _polyglot.t(input, { smart_count: options })
    }
  }

  var setCsLocale = function () {
    // part of https://github.com/angular/angular.js/blob/v1.5.x/src/ngLocale/angular-locale_cs.js
    $provide.value('$locale', {
      'DATETIME_FORMATS': {
        'AMPMS': [
          'dopoledne',
          'odpoledne'
        ],
        'DAY': [
          'ned\u011ble',
          'pond\u011bl\u00ed',
          '\u00fater\u00fd',
          'st\u0159eda',
          '\u010dtvrtek',
          'p\u00e1tek',
          'sobota'
        ],
        'ERANAMES': [
          'p\u0159. n. l.',
          'n. l.'
        ],
        'ERAS': [
          'p\u0159. n. l.',
          'n. l.'
        ],
        'FIRSTDAYOFWEEK': 0,
        'MONTH': [
          'ledna',
          '\u00fanora',
          'b\u0159ezna',
          'dubna',
          'kv\u011btna',
          '\u010dervna',
          '\u010dervence',
          'srpna',
          'z\u00e1\u0159\u00ed',
          '\u0159\u00edjna',
          'listopadu',
          'prosince'
        ],
        'SHORTDAY': [
          'ne',
          'po',
          '\u00fat',
          'st',
          '\u010dt',
          'p\u00e1',
          'so'
        ],
        'SHORTMONTH': [
          'led',
          '\u00fano',
          'b\u0159e',
          'dub',
          'kv\u011b',
          '\u010dvn',
          '\u010dvc',
          'srp',
          'z\u00e1\u0159',
          '\u0159\u00edj',
          'lis',
          'pro'
        ],
        'STANDALONEMONTH': [
          'leden',
          '\u00fanor',
          'b\u0159ezen',
          'duben',
          'kv\u011bten',
          '\u010derven',
          '\u010dervenec',
          'srpen',
          'z\u00e1\u0159\u00ed',
          '\u0159\u00edjen',
          'listopad',
          'prosinec'
        ],
        'WEEKENDRANGE': [
          5,
          6
        ],
        'fullDate': 'EEEE d. MMMM y',
        'longDate': 'd. MMMM y',
        'medium': 'd. M. y H:mm:ss',
        'mediumDate': 'd. M. y',
        'mediumTime': 'H:mm:ss',
        'short': 'dd.MM.yy H:mm',
        'shortDate': 'dd.MM.yy',
        'shortTime': 'H:mm'
      },
      'NUMBER_FORMATS': {
        'CURRENCY_SYM': 'K\u010d',
        'DECIMAL_SEP': ',',
        'GROUP_SEP': '\u00a0',
        'PATTERNS': [
          {
            'gSize': 3,
            'lgSize': 3,
            'maxFrac': 3,
            'minFrac': 0,
            'minInt': 1,
            'negPre': '-',
            'negSuf': '',
            'posPre': '',
            'posSuf': ''
          },
          {
            'gSize': 3,
            'lgSize': 3,
            'maxFrac': 2,
            'minFrac': 2,
            'minInt': 1,
            'negPre': '-',
            'negSuf': '\u00a0\u00a4',
            'posPre': '',
            'posSuf': '\u00a0\u00a4'
          }
        ]
      },
      'id': 'cs',
      'localeID': 'cs'
    })
  }

  var setEnLocale = function () {
    // https://github.com/angular/angular.js/blob/v1.5.x/src/ngLocale/angular-locale_en.js
    $provide.value('$locale', {
      'DATETIME_FORMATS': {
        'AMPMS': [
          'AM',
          'PM'
        ],
        'DAY': [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ],
        'ERANAMES': [
          'Before Christ',
          'Anno Domini'
        ],
        'ERAS': [
          'BC',
          'AD'
        ],
        'FIRSTDAYOFWEEK': 6,
        'MONTH': [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        'SHORTDAY': [
          'Sun',
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat'
        ],
        'SHORTMONTH': [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        'STANDALONEMONTH': [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        'WEEKENDRANGE': [
          5,
          6
        ],
        'fullDate': 'EEEE, MMMM d, y',
        'longDate': 'MMMM d, y',
        'medium': 'MMM d, y h:mm:ss a',
        'mediumDate': 'MMM d, y',
        'mediumTime': 'h:mm:ss a',
        'short': 'M/d/yy h:mm a',
        'shortDate': 'M/d/yy',
        'shortTime': 'h:mm a'
      },
      'NUMBER_FORMATS': {
        'CURRENCY_SYM': '$',
        'DECIMAL_SEP': '.',
        'GROUP_SEP': ',',
        'PATTERNS': [
          {
            'gSize': 3,
            'lgSize': 3,
            'maxFrac': 3,
            'minFrac': 0,
            'minInt': 1,
            'negPre': '-',
            'negSuf': '',
            'posPre': '',
            'posSuf': ''
          },
          {
            'gSize': 3,
            'lgSize': 3,
            'maxFrac': 2,
            'minFrac': 2,
            'minInt': 1,
            'negPre': '-\u00a4',
            'negSuf': '',
            'posPre': '\u00a4',
            'posSuf': ''
          }
        ]
      },
      'id': 'en',
      'localeID': 'en'
    })
  }

  this.$get = function () {
    return this
  }
}

exports.i18n = i18n
