appLoader.$inject = []

function appLoader () {
  var loaderElement = null
  var showWithDelay = false
  var timeoutId = null

  this.init = function (widgetElement) {
    if (widgetElement) {
      loaderElement = widgetElement.getElementsByClassName('external-widget-loader-loading')[0]
    }
  }

  this.show = function () {
    showWithDelay = true
    timeoutId && clearTimeout(timeoutId)
    timeoutId = setTimeout(function () {
      if (loaderElement && showWithDelay) {
        showWithDelay = false
        loaderElement.style.display = 'block'
      }
    }, 1000)
  }

  this.showImmediate = function () {
    if (loaderElement) {
      loaderElement.style.display = 'block'
    }
  }

  this.hide = function () {
    if (loaderElement) {
      showWithDelay = false
      timeoutId && clearTimeout(timeoutId)
      loaderElement.style.display = 'none'
    }
  }

  this.setBackgroundColor = function (color) {
    try {
      loaderElement.children[0].style.backgroundColor = color
    } catch (e) {}
  }

  this.$get = function () {
    return this
  }
}

exports.appLoader = appLoader
