var angular = require('angular')

httpErrorInterceptor.$inject = ['$q', 'logglyHttpWrapperService']

function httpErrorInterceptor ($q, logglyHttpWrapperService) {
  return {
    request: function (config) {
      if (!angular.isFunction(config.logToLogglyCondition)) {
        config.logToLogglyCondition = function (rejection) {
          // Suppress:
          //  * deadly logging loop of loggly.com error
          //  * logging cancelled requests (status === -1)
          return rejection && rejection.config && rejection.config.url && !~rejection.config.url.indexOf('loggly.com') && ~rejection.status
        }
      }
      return config
    },
    requestError: function (rejection) {
      return $q.reject(rejection)
    },
    response: function (response) {
      return response
    },
    responseError: function (rejection) {
      if (rejection && rejection.config && angular.isFunction(rejection.config.logToLogglyCondition) && rejection.config.logToLogglyCondition(rejection)) {
        logglyHttpWrapperService.error(rejection)
      }
      return $q.reject(rejection)
    }
  }
}

exports.httpErrorInterceptor = httpErrorInterceptor
