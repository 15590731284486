helperService.$inject = ['$filter', '$http', '$locale']

function helperService ($filter, $http, $locale) {
  return {
    getRestDate: function (date) {
      if (date) {
        var dateExplode = date.split('.')
        date = new Date(dateExplode[2], dateExplode[1] - 1, dateExplode[0])
      } else {
        date = new Date()
      }

      return $filter('date')(date, 'yyyy-MM-dd')
    },

    getRestDateTime: function (date, time) {
      if (date && time) {
        var dateExplode = date.split('.')
        var timeExplode = time.split(':')
        date = new Date(dateExplode[2], dateExplode[1] - 1, dateExplode[0], timeExplode[0], timeExplode[1])
      } else {
        date = new Date()
      }

      return $filter('date')(date, 'yyyy-MM-ddTHH:mm:ss')
    },

    getUxDate: function (dateStamp) {
      if (!dateStamp) dateStamp = new Date()

      return $filter('date')(dateStamp, 'dd. MM. yyyy')
    },

    getUxDateTime: function (dateStamp) {
      if (!dateStamp) dateStamp = new Date()

      return {
        date: $filter('date')(dateStamp, 'dd. MM. yyyy'),
        time: $filter('date')(dateStamp, 'HH:mm')
      }
    },

    abortCall: function (name) {
      $http.pendingRequests.forEach(function (request) {
        if (name) {
          if (request.cancel && request.timeout && request.name === name) {
            request.cancel.resolve()
          }
        } else {
          if (request.cancel && request.timeout) {
            request.cancel.resolve()
          }
        }
      })
    },

    chartLocal: function () {
      return {
        rangeSelectorZoom: $locale.id === 'cs' ? 'Časové rozpětí: ' : null,
        months: $locale.DATETIME_FORMATS.STANDALONEMONTH,
        weekdays: $locale.DATETIME_FORMATS.DAY,
        shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH
      }
    }
  }
}

exports.helperService = helperService
